<template>
  <div>
    <Breadcrumbs page_title="banner" :items="breadcrumbs" />

    <v-container fluid>
      <v-row>
        <template>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
            v-for="item in bannerItems"
            :key="item.id"
            class="library"
          >
            <v-card
              class="mx-auto library_card"
              style="border-radius: 16px; padding: 15px;"
              elevation="3"
              height="235"
            >
              <v-row>
                <v-col cols="12" md="7" style="padding-left: 0;padding-right: 0;padding-top: 12px;">
                  <v-img :src="item.orgImage" height="200px" class="library_img"></v-img>
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                  style="padding-left: 5px;padding-right: 5px;padding-top: 12px;"
                >
                  <div style="display: flex; flex-direction: column; height: 100%;">
                    <div>
                      <p
                        class="text_block mt-3"
                        style="
                    font-size: 18px;
                    color: #424242;
                    font-weight: bold;                
                  "
                      >{{ item.title }}</p>
                      <p
                        style="
                    font-size: 14px;
                    color: #777777;
                    margin-bottom: 0;"
                      >{{ item.description}}</p>
                      <span
                        style="
                    font-size: 14px;
                    color: #777777;"
                      >{{ item.start_time +" - "+ item.end_time}}</span>
                    </div>
                    <div style="margin-top: auto;">
                      <v-col class="pl-0 pr-0">
                        <v-btn
                          width="110"
                          class="text-capitalize white--text mt-4"
                          style="border-radius: 8px;font-size: 16px;cursor: pointer;"
                          color="#4FB14E"
                          @click="clickLink(item.linkURL)"
                        >{{ item.buttonName }}</v-btn>
                      </v-col>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import * as moment from "moment/moment";

export default {
  data() {
    return {
      bannerItems: [],
      breadcrumbs: [
        {
          text: "home",
          href: "/home"
        },
        {
          text: "banner"
        }
      ]
    };
  },
  computed: {},
  mounted() {
    this.getMobileBanner();
  },
  methods: {
    clickLink(link) {
      window.open(link, "_blank");
    },
    async getMobileBanner() {
      let companyID = localStorage.getItem("companyID");

      const res = await this.$axios.get(
        `${this.web_url}Banner/GetBannerMobile?CompanyID=` + companyID
      );

      if (res.data.status == 0) {
        this.bannerItems = res.data.data.map(v => ({
          ...v,
          start_time: moment(v.startTime)
            .local()
            .format("LT"),
          end_time: moment(v.endTime)
            .local()
            .format("LT")
        }));
      }
    }
  }
};
</script>

<style scoped>
.library_img {
  margin: 0px 12px;
  border-radius: 16px;
}
</style>